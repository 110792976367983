import React, { useEffect, useState, createElement } from "react";
import Card from "./common/card";
import QuestTextBox from "./common/textbox";
import QuestButton from "./common/button";
import TextEditor from "./common/texteditor";
import SuggestionInput from "./common/SuggestInput";

import Chips from "./common/chips";

const QuestionForm = (props) => {
  //props
  const {
    formErrors,
    setShowFields,
    questionIndex,
    formSucess,
    clearSucess,
    mockData,
    available,
    showFields,
    input,
    tags,
  } = props;

  //hooks

  //state
  const [pendingUI, setpendingUI] = useState(false);

  //instance funtions

  //Submit Handler
  const onSubmit = () => {
    props.onSubmit({ ...input, tags });
    setpendingUI(true);
  };

  useEffect(() => {
    if (formErrors || questionIndex) {
      const timer = setTimeout(() => {
        clearSucess();
        setpendingUI(false);
        return () => clearTimeout(timer);
      }, 1000);
    }
  }, [formErrors, questionIndex, clearSucess]);

  return (
    <>
      {mockData.header && (
        <Card
          additionalClasses={mockData.header.class}
          textAlign={mockData.header.textAlign}
        >
          {mockData.header.cardTitle &&
            createElement(
              mockData.header.cardTitle.ele,
              {},
              mockData.header.cardTitle.content
            )}
          {mockData.header.content ? mockData.header.content : ""}
        </Card>
      )}

      {mockData.title && (
        <Card
          additionalClasses={mockData.title.class}
          style_={mockData.title.style_}
          textAlign={mockData.title.textAlign}
        >
          {mockData.title.cardTitle &&
            createElement(
              mockData.title.cardTitle.ele,
              {},
              mockData.title.cardTitle.content
            )}
          {mockData.title.content ? mockData.title.content : ""}
          <QuestTextBox
            placeholder={mockData.title.questTextBox.placeholder}
            id={mockData.title.questTextBox.id}
            name={mockData.title.questTextBox.name}
            value={input.title}
            onChange={props.onChangehandler}
            error={props.formErrors?.title}
          />
          {showFields === 0 && mockData.title.questButton && (
            <QuestButton
              id={mockData.title.questButton.id}
              value={mockData.title.questButton.value}
              onClick={() => {
                setShowFields((prev) => ++prev);
              }}
              disabled={input.title.length === 0}
            />
          )}
        </Card>
      )}

      {mockData.questionDescription && (
        <Card
          additionalClasses={`${mockData.questionDescription.class} + ${
            showFields < 1 ? "blur_content" : ""
          }`}
          style_={mockData.questionDescription.style_}
          textAlign={mockData.questionDescription.textAlign}
        >
          {mockData.questionDescription.cardTitle &&
            createElement(
              mockData.questionDescription.cardTitle.ele,
              {},
              mockData.questionDescription.cardTitle.content
            )}
          {mockData.questionDescription.content
            ? mockData.questionDescription.content
            : ""}

          <TextEditor
            placeholder={mockData.questionDescription.textEditor.placeholder}
            id={mockData.questionDescription.textEditor.id}
            name={mockData.questionDescription.textEditor.name}
            focus={mockData.questionDescription.textEditor.focus}
            onChange={props.onChangehandler}
            value={input.questionDescription}
            error={props.formErrors?.questionDescription}
          />
          {showFields === 1 && mockData.questionDescription.questButton && (
            <QuestButton
              id={mockData.questionDescription.questButton.id}
              value={mockData.questionDescription.questButton.value}
              onClick={() => {
                setShowFields((prev) => ++prev);
              }}
              disabled={input.questionDescription.length < 20}
            />
          )}
        </Card>
      )}
      {mockData.codeSnippet && (
        <Card
          additionalClasses={`${mockData.codeSnippet.class} + ${
            showFields < 2 ? "blur_content" : ""
          }`}
          style_={mockData.codeSnippet.style_}
          textAlign={mockData.codeSnippet.textAlign}
        >
          {mockData.codeSnippet.cardTitle &&
            createElement(
              mockData.codeSnippet.cardTitle.ele,
              {},
              mockData.codeSnippet.cardTitle.content
            )}
          {mockData.codeSnippet.content ? mockData.codeSnippet.content : ""}
          {mockData.codeSnippet.textEditor && (
            <TextEditor
              placeholder={mockData.codeSnippet.textEditor.placeholder}
              id={mockData.codeSnippet.textEditor.id}
              name={mockData.codeSnippet.textEditor.name}
              onChange={props.onChangehandler}
              value={input.codeSnippet}
              error={props.formErrors?.codeSnippet}
            />
          )}
          {mockData.codeSnippet.questButton && showFields === 2 && (
            <QuestButton
              id={mockData.codeSnippet.questButton.id}
              value={mockData.codeSnippet.questButton.value}
              onClick={() => {
                setShowFields((prev) => ++prev);
              }}
              disabled={input.codeSnippet.length < 20}
            />
          )}
        </Card>
      )}
      {mockData.tags && (
        <Card
          additionalClasses={`${mockData.tags.class} + ${
            showFields < 3 ? "blur_content" : ""
          }`}
          style_={mockData.tags.style_}
          textAlign={mockData.tags.textAlign}
        >
          {mockData.tags.cardTitle &&
            createElement(
              mockData.tags.cardTitle.ele,
              {},
              mockData.tags.cardTitle.content
            )}
          {mockData.tags.content ? mockData.tags.content : ""}
          {mockData.tags.suggestInput && (
            <SuggestionInput
              placeHolder={mockData.tags.suggestInput.placeholder}
              id={mockData.tags.suggestInput.id}
              name={mockData.tags.suggestInput.name}
              className={mockData.tags.suggestInput.class}
              available={available}
              tags={tags}
              onChange={props.tagChange}
              error={props.formErrors?.tags}
            />
          )}
          {mockData.tags.questButton && showFields === 3 && (
            <QuestButton
              id={mockData.tags.questButton.id}
              value={mockData.tags.questButton.value}
              onClick={() => setShowFields((prev) => ++prev)}
              disabled={tags.length === 0}
            />
          )}
          {mockData.tags.Questionflag && (
            <>
              <div
                className={`${mockData.tags.Questionflag.class} ${
                  showFields < 4 ? "blur_content" : ""
                }`}
              >
                <label
                  htmlFor={mockData.tags.Questionflag.questTextBox.id}
                  title={mockData.Questionflag?.questTextBox?.title}
                >
                  <QuestTextBox
                    type={mockData.tags.Questionflag.questTextBox.type}
                    id={mockData.tags.Questionflag.questTextBox.id}
                    name={mockData.tags.Questionflag.questTextBox.name}
                    checked={input.anonymousFlag}
                    onChange={props.onChangehandler}
                    title={mockData.tags.Questionflag.questTextBox.title}
                  />
                  {mockData.tags.Questionflag.content}
                </label>
                <br />
                <span>
                  {mockData.tags.Questionflag.hint}
                  <br />
                  {mockData.tags.Questionflag.questChips && (
                    <Chips
                      hover={mockData.tags.Questionflag.questChips.hover}
                      style_={mockData.tags.Questionflag.questChips.style_}
                      value={mockData.tags.Questionflag.questChips.value}
                    />
                  )}
                </span>
              </div>
              {mockData.tags.Questionflag.questButton && showFields === 4 && (
                <QuestButton
                  id={mockData.tags.Questionflag.questButton.id}
                  value={mockData.tags.Questionflag.questButton.value}
                  onClick={() => setShowFields((prev) => ++prev)}
                  disabled={tags.length === 0}
                />
              )}
            </>
          )}
        </Card>
      )}

      {formErrors?.postError ? (
        <em className="error-red">{formErrors.postError}</em>
      ) : null}

      {formSucess ? <em className="suceess-green">{formSucess}</em> : null}
      {mockData.formButton && (
        <div
          className={`${mockData.formButton.class} ${
            showFields < 5 ? "blur_content" : ""
          } `}
        >
          <QuestButton
            type={mockData.formButton.type}
            id={mockData.formButton.id}
            value={
              pendingUI
                ? mockData.formButton.pendingValue
                : mockData.formButton.value
            }
            disabled={
              formErrors?.title ||
              formErrors?.questionDescription ||
              formErrors?.codeSnippet ||
              formErrors?.tag
            }
            onClick={onSubmit}
          />
        </div>
      )}
    </>
  );
};

export default QuestionForm;
