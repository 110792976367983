export const mock_postQuestion = {
  page: "postQuestion",
  header: {
    class: "suggestion",
    textAlign: "left",
    cardTitle: { ele: "h2", content: "Writing a good question" },
    content: (
      <>
        <p>
          You’re ready to ask a programming-related question and this form will
          help guide you through the process.
        </p>
        <p>
          Looking to ask a non-programming question? See the topics here to find
          a relevant site.
        </p>
        <h3>Steps</h3>
        <ul>
          <li>Summarize your problem in a one-line title.</li>
          <li>Describe your problem in more detail.</li>
          <li>Describe what you tried and what you expected to happen.</li>
          <li>
            Add “tags” which help surface your question to members of the
            community.
          </li>
          <li>Review your question and post it to the site.</li>
        </ul>
      </>
    ),
  },
  title: {
    class: "post-title",
    textAlign: "left",
    style_: "white",
    cardTitle: { ele: "h3", content: "Title" },
    content: <p>Be specific while writing the question..</p>,
    questTextBox: {
      placeholder:
        "e.g. Is there an R function for finding the index of an element in a vector?",
      id: "postQuestion_title",
      name: "postQuestion_title",
    },
    questButton: {
      id: "postQuestion_title-button",
      value: "Next",
    },
  },
  questionDescription: {
    class: "post-problem",
    textAlign: "left",
    style_: "white",
    cardTitle: {
      ele: "h3",
      content: "What are the details of your problem?",
    },
    content: (
      <p>
        Introduce the problem and expand on what you put in the title. Minimum
        20 characters.
      </p>
    ),
    textEditor: {
      placeholder:
        "e.g. Is there an R function for finding the index of an element in a vector?",
      id: "post_questionDescription",
      name: "post_questionDescription",
      focus: true,
    },

    questButton: {
      id: "post_questionDescription-button",
      value: "Next",
    },
  },
  codeSnippet: {
    textAlign: "left",
    style_: "white",
    cardTitle: {
      ele: "h3",
      content: "What did you try and what were you expecting?",
    },
    content: (
      <p>
        Be explicit/ specific, as if you were asking a question to someone else.
      </p>
    ),
    textEditor: {
      placeholder:
        "e.g. Is there an R function for finding the index of an element in a vector?",
      id: "post_codeSnippet",
      name: "post_codeSnippet",
    },
    questButton: {
      id: "post_codeSnippet-button",
      value: "Next",
    },
  },
  tags: {
    class: "post-tags",
    textAlign: "left",
    style_: "white",
    cardTitle: {
      ele: "h3",
      content: "Tags",
    },
    content: (
      <p>
        Add up to 5 tags to describe what your question is about. Start typing
        to see suggestions.
      </p>
    ),
    suggestInput: {
      placeholder: "enter tags",
      id: "postQuestionTags",
      name: "postQuestionTags",
      class: "suggestInput-container",
    },
    questButton: {
      id: "postQuestionTags-button",
      value: "Next",
    },
    Questionflag: {
      class: "formQuestion-flag",
      questTextBox: {
        type: "checkbox",
        id: "postQuestion_anonymousFlag",
        name: "postQuestion_anonymousFlag",
        title: "Click if you don't want to show your name with Question",
      },
      title: "Click if you don't want to show your name with Question",
      content: "Anonymous",
      hint: <>&nbsp;&nbsp; Optional !</>,
      questChips: {
        hover: "true",
        style_: "primary",
        value: <p>Click if you don't want to show your name with Question</p>,
      },
      questButton: {
        id: "postQuestion-flag-button",
        value: "Next",
      },
    },
  },
  formButton: {
    class: "questionForm-button",
    type: "submit",
    id: "questionPost-button",
    value: "Post Question",
    pendingValue: "Posting...",
  },
};

export const mock_editQuestion = {
  page: "editQuestion",
  header: {
    class: "suggestion",
    textAlign: "left",
    cardTitle: { ele: "h2", content: "How to Edit" },
    content: (
      <>
        <ul>
          <li>fix grammatical or spelling errors</li>
          <li>clarify meaning without changing it</li>
          <li>correct minor mistakes</li>
          <li>add related resources or links</li>
          <li>always respect to the original author</li>
        </ul>
      </>
    ),
  },
  title: {
    class: "edit-title",
    textAlign: "left",
    style_: "white",
    cardTitle: { ele: "h3", content: "Title" },
    content: <p>Be specific while writing the question...</p>,
    questTextBox: {
      placeholder:
        "e.g. Is there an R function for finding the index of an element in a vector?",
      id: "editQuestion_title",
      name: "editQuestion_title",
    },
    questButton: {
      id: "editQuestion_title-button",
      value: "Next",
    },
  },
  questionDescription: {
    class: "edit-problem",
    textAlign: "left",
    style_: "white",
    cardTitle: {
      ele: "h3",
      content: "What are the details of your problem?",
    },
    content: (
      <p>
        Introduce the problem and expand on what you put in the title. Minimum
        20 characters.
      </p>
    ),
    textEditor: {
      placeholder:
        "e.g. Is there an R function for finding the index of an element in a vector?",
      id: "edit_questionDescription",
      name: "edit_questionDescription",
      focus: true,
    },

    questButton: {
      id: "edit_questionDescription-button",
      value: "Next",
    },
  },
  codeSnippet: {
    textAlign: "left",
    style_: "white",
    cardTitle: {
      ele: "h3",
      content: "What did you try and what were you expecting?",
    },
    content: (
      <p>
        Be explicit/ specific, as if you were asking a question to someone else.
      </p>
    ),
    textEditor: {
      placeholder:
        "e.g. Is there an R function for finding the index of an element in a vector?",
      id: "edit_codeSnippet",
      name: "edit_codeSnippet",
    },
    questButton: {
      id: "edit_codeSnippet-button",
      value: "Next",
    },
  },
  tags: {
    class: "edit-tags",
    textAlign: "left",
    style_: "white",
    cardTitle: {
      ele: "h3",
      content: "Tags",
    },
    content: (
      <p>
        Add up to 5 tags to describe what your question is about. Start typing
        to see suggestions.
      </p>
    ),
    suggestInput: {
      placeholder: "enter tags",
      id: "postQuestionTags",
      name: "postQuestionTags",
      class: "suggestInput-container",
    },
    questButton: {
      id: "postQuestionTags-button",
      value: "Next",
    },
    Questionflag: {
      class: "formQuestion-flag",
      questTextBox: {
        type: "checkbox",
        id: "postQuestion_anonymousFlag",
        name: "postQuestion_anonymousFlag",
        title: "Click if you don't want to show your name with Question",
      },
      title: "Click if you don't want to show your name with Question",
      content: "Anonymous",
      hint: <>&nbsp;&nbsp; Optional !</>,
      questChips: {
        hover: "true",
        style_: "primary",
        value: <p>Click if you don't want to show your name with Question</p>,
      },
      questButton: {
        id: "postQuestion-flag-button",
        value: "Next",
      },
    },
  },
  formButton: {
    class: "questionForm-button",
    type: "submit",
    id: "questionEdit-button",
    value: "Update Question",
    pendingValue: "Updating...",
  },
};
