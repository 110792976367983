import classNames from "classnames";

const Chips = (props) => {
  const chipsClassNames = classNames(
    "chips",
    props.style_,
    props.additionalClasses
  );
  return (
    <div
      className={chipsClassNames}
      disablehover={props.hover ? props.hover : "false"}
    >
      {props.value}
    </div>
  );
};
export default Chips;
