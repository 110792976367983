import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  formError: "",
  formSucess: undefined,
};
const formSlice = createSlice({
  name: "Form",
  initialState,
  reducers: {
    addError: (state, action) => {
      state.formError = action.payload;
    },
    addSucess: (state, action) => {
      state.formSucess = action.payload;
    },
    clearError: (state, action) => {
      state.formError = "";
    },
    clear_Sucess: (state, action) => {
      state.formSucess = "";
    },
    clearErrorOnChangeError: (state, action) => {
      switch (action.payload.split("-")[0]) {
        case "comment":
          state.formError[action.payload.split("-")[0]][action.payload] = "";
          break;

        default:
          state.formError[action.payload] = "";
          break;
      }
    },
  },
});

export default formSlice.reducer;
export const {
  addError,
  addSucess,
  clearError,
  clear_Sucess,
  clearErrorOnChangeError,
} = formSlice.actions;
