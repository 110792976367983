import { sendRequest } from "../mock/customHook";
import {
  addAnswer,
  update_Answer,
  deleteAnswer,
} from "../store/slices/AnswerSlice";
import {
  addError,
  addSucess,
  clearError,
  clear_Sucess,
  clearErrorOnChangeError,
} from "../store/slices/FormSlice";

//add Answers
export const postAnswer = (data) => async (dispatch) => {
  const { answer, anonymousFlag, questionId } = data;

  const errors = {
    answerDescription:
      answer.trim().length > 20
        ? null
        : "Answer should be greater than 20 characters?",
  };
  const hasErrors = Object.values(errors).some((errorMessage) => errorMessage);

  if (hasErrors) return await dispatch(addError({ answer: errors }));

  //create Answer
  const newAnswer = {
    answerDescription: answer,
    codeSnippet: "",
    userId: 123,
    userName: "testName",
    anonymousFlag: anonymousFlag,
  };

  const res = await sendRequest({
    method: "post",
    url: `/question/${questionId}/answers`,
    body: newAnswer,
  });

  if (res.request.status === 200) {
    await dispatch(clearError());
    await dispatch(addAnswer({ ...newAnswer, answerId: res.data.answerId }));
    await dispatch(addSucess({ answer: res.data.response.description }));
  } else await dispatch(addError({ answer: { postError: res.message } }));
};

//update Answer
export const updateAnswer = (data) => async (dispatch) => {
  const { answer, anonymousFlag, answerId, questionId } = data;

  const errors = {
    answerDescription:
      answer.trim().length > 20
        ? null
        : "Answer should be greater than 20 characters?",
  };
  const hasErrors = Object.values(errors).some((errorMessage) => errorMessage);

  if (hasErrors) return await dispatch(addError({ answer: errors }));

  const Answer = {
    answerDescription: answer,
    codeSnippet: "",
    userId: 123,
    userName: "testName",
    anonymousFlag: anonymousFlag,
  };

  const res = await sendRequest({
    method: "put",
    url: `/question/${questionId}/answers/${answerId}`,
    body: Answer,
  });

  if (res?.request?.status === 200) {
    await dispatch(clearError());
    await dispatch(addSucess({ answerUpdated: res.data.response.description }));
    await dispatch(update_Answer({ ...Answer, answerId }));
  } else await dispatch(addError({ answer: { postError: res.message } }));
};

export const deleteanswer = (data) => async (dispatch) => {
  const { answerId, questionId } = data;

  const res = await sendRequest({
    method: "delete",
    url: `/question/${questionId}/answers/${answerId}`,
  });

  if (res?.request?.status === 200) {
    await dispatch(clearError());
    await dispatch(deleteAnswer(answerId));
    await dispatch(addSucess({ answerDeleted: res.data.response.description }));
  } else
    await dispatch(
      addError({ [`answerDeleted-${questionId}-${answerId}`]: res.message })
    );
};

//clear success message
export const clearSucess = () => async (dispatch) => {
  await dispatch(clear_Sucess());
};

//clear invididual error on input change
export const resetError = (id) => async (dispatch) => {
  if (id) await dispatch(clearErrorOnChangeError(id));
  else await dispatch(clearError());
};
