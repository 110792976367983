import { Link } from "react-router-dom";
// import QuestTextBox from "./textbox";
import QuestButton from "./button";
import { useNavigate } from "react-router-dom";

const Header = (props) => {
  //naviagate hook
  const navigate = useNavigate();

  return (
    <header className="app-header component-container">
      <Link to="/">
        <img src="/logo512.png" className="logo" alt="logo" />
      </Link>

      <div className="login-link-container">
        <QuestButton
          value="Ask Question"
          onClick={() => {
            navigate("/postQuestion");
          }}
        />
        {/* // can be used for Day2 */}
        {/* <ul>
          <li>
            <Link to="searchResults">About</Link>
          </li>
          <li>
            <a href="./#">Products</a>
          </li>
          <li>
            <a href="./#">For Teams</a>
          </li>
          <li>
            <a href="./#">Login</a>
          </li>
          <li>
            <a href="./#">Signup</a>
          </li>
        </ul>
        <div className="search-container">
          <QuestTextBox
            type="text"
            name="search"
            placeHolder="Search..."
            id="main-search"
          />
        </div> */}
      </div>
    </header>
  );
};
export default Header;
