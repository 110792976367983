const SideNav = (props) => {
  return (
    <>
      {/* Dummy  checkbox for sidebar collapse*/}
      <input type="checkbox" id="sidebar_toggle_check" />

      <div className="side-nav box-shadow-default" id="sideNav">
        <ul>
          <li>
            <a href="/">Home</a>
          </li>
          <li>
            <a href="/tags">Tags</a>
          </li>
          <li>
            <a href={`/question/${props.questionId}`} className="selected">
              Questions
            </a>
            {/* <ul> // Submenu structure
                            <li><a href="./#">Tags</a></li>
                            <li><a href="./#">Users</a></li>
                            <li><a href="./#">Companies</a></li>
                        </ul> */}
          </li>
        </ul>
      </div>
      <label htmlFor="sidebar_toggle_check" className="sidebar_toggle">
        <i className="bi bi-arrow-bar-left"></i>
        <i className="bi bi-arrow-bar-right"></i>
      </label>
    </>
  );
};
export default SideNav;
