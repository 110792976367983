import React, { useEffect, useState } from "react";
import Author from "./Author";
import CommentInput from "./CommentInput";

const CommentItem = (props) => {
  const { comment, type, item, Success, questionId } = props;
  const [editForm, setEditForm] = useState(false);
  const [Pend, setPend] = useState(false);

  const onEditHandler = () => {
    if (props.isOpen === false) {
      setEditForm(true);
      props.setIsOpen(true);
    }
  };

  const onCancelClick = () => {
    setEditForm(false);
    props.setIsOpen(false);
  };
  const onDeleteClick = (e) => {
    props.deletecomment({
      commentId: comment.commentId,
      commentType: type + "-" + item[`${type}Id`],
      questionId,
    });
    setPend(true);
  };
  useEffect(() => {
    if (Success) setPend("");
  }, [Success]);

  return editForm ? (
    <div className="reply-input">
      <CommentInput
        id={type + "-" + item[`${type}Id`]}
        {...props}
        formErrors={props.formErrors?.comment}
        item={comment}
        formtype="updatecomment"
        formSuccess={props.formSuccess?.comment}
        cancel={onCancelClick}
      />
    </div>
  ) : (
    <>
      <div className="comment-section " style={{ opacity: Pend ? "0.25" : 1 }}>
        <div className="comment">
          <p>
            <span className="vote">0</span> {comment.commentDescription}
          </p>

          <Author
            {...props}
            item={comment}
            delete="delete comment"
            type="comment"
            id={comment.commentId}
            edit="edit comment"
            onEditClick={onEditHandler}
            onDelete={onDeleteClick}
          />
        </div>
      </div>
      {Pend ? (
        <div className="comment-deleted">Comment Deleted Successfully</div>
      ) : null}
    </>
  );
};

export default CommentItem;
