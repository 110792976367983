export const questions = [
  {
    question: {
      questionId: 1,
      title: "How to convert string to string array?",
      questionDescription:
        "I am trying to convert string to string array, is there any built in function?",
      codeSnippet: "string = name",
      userId: 1234,
      userName: "testName",
      anonymousFlag: "N",
      questionStatus: "closed",
      createdOn: "2019-10-11T13:33:05.673",
      modifiedOn: "2019-10-12T13:33:05.673",
      tags: [
        {
          tagId: 1,
          tagName: "data type",
        },
        {
          tagId: 2,
          tagName: "type conversion",
        },
      ],
    },
    comments: [
      {
        commentId: 23,
        userId: "01243",
        userName: "testName",
        createdOn: "2019-10-11T13:33:05.673",
        modifiedOn: "2019-10-12T13:33:05.673",
        anonymousFlag: "N",
        commentDescription: "Can you elaborate your question please?",
      },
      {
        commentId: 22,
        userId: "0123",
        userName: "testName",
        createdOn: "2019-10-11T13:33:05.673",
        modifiedOn: "2019-10-12T13:33:05.673",
        anonymousFlag: "N",
        commentDescription:
          "Do you want to convert the entire word name as an array?",
      },
    ],
    answers: [
      {
        answerId: 0,
        answerDescription: "first answer",
        codeSnippet: "test",
        userId: 123,
        userName: "testName",
        anonymousFlag: "N",
        createdOn: "2019-10-11T13:33:05.673",
        modifiedOn: "2019-10-12T13:33:05.673",
      },
      {
        answerId: 1,
        answerDescription: "second answer",
        codeSnippet: "test",
        userId: 234,
        userName: "testName",
        anonymousFlag: "N",
        createdOn: "2019-10-15T13:33:05.673",
        modifiedOn: "2019-10-12T13:33:05.673",

        comments: [
          {
            commentId: 22,
            userId: 234,
            userName: "testName",
            createdOn: "2019-11-11T13:33:05.673",
            modifiedOn: "2019-10-12T13:33:05.673",
            anonymousFlag: "Y",
            commentDescription: "Can you elaborate your question please?",
          },
          {
            commentId: 23,
            userId: 235,
            userName: "testName",
            createdOn: "2019-10-12T13:33:05.673",
            modifiedOn: "2019-10-12T13:33:05.673",
            anonymousFlag: "N",
            commentDescription:
              "Do you want to convert the entire word name as an array?",
          },
        ],
      },
    ],
  },
];

export const tags = [
  {
    tagId: 1,
    tagName: "data type",
  },
  {
    tagId: 2,
    tagName: "type conversion",
  },
  {
    tagId: 3,
    tagName: "React",
  },
  {
    tagId: 4,
    tagName: "Redux",
  },
  {
    tagId: 5,
    tagName: "JavaScript",
  },
  {
    tagId: 6,
    tagName: "Cpp",
  },
  {
    tagId: 7,
    tagName: "Python",
  },
  {
    tagId: 8,
    tagName: "Node",
  },
  {
    tagId: 9,
    tagName: "Ruby",
  },
  {
    tagId: 10,
    tagName: "SharePoint",
  },
];

export const URL = "/mphasis-sparkle-quest-site";
export const API_endpoint =
  "http://quest-backend-alb-660147533.ap-south-1.elb.amazonaws.com";
