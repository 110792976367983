import React, { useRef } from "react";
import { Editor } from "@tinymce/tinymce-react";
const TextEditor = ({
  placeholder,
  tabIndex,
  id,
  name,
  onChange,
  error,
  value,
  height,
}) => {
  const editorRef = useRef(null);

  const onChangeHandler = (content, editor) => {
    onChange({ id: id, value: content });
  };

  return (
    <div tabIndex={tabIndex} id={id} name={name}>
      <Editor
        apiKey={process.env.REACT_APP_EDITOR_API}
        onInit={(evt, editor) => (editorRef.current = editor)}
        onFocus={(e) => e.target.editorContainer.classList.toggle("focused")}
        onBlur={(e) => e.target.editorContainer.classList.toggle("focused")}
        init={{
          placeholder: `${placeholder}`,
          menubar: false,
          plugins: "link codesample lists wordcount autosave table anchor",
          toolbar:
            "undo codesample redo | styles | forecolor backcolor | bold italic underline strikethrough | align | table link | bullist numlist outdent indent | code",
          height: height || 200,
          codesample_languages: [
            { text: "HTML/XML", value: "markup" },
            { text: "JavaScript", value: "javascript" },
            { text: "CSS", value: "css" },
            { text: "PHP", value: "php" },
            { text: "Ruby", value: "ruby" },
            { text: "Python", value: "python" },
            { text: "Java", value: "java" },
            { text: "C", value: "c" },
            { text: "C#", value: "csharp" },
            { text: "C++", value: "cpp" },
          ],
          typography_langs: ["en-US"],
          typography_default_lang: "en-US",
          advcode_inline: true,
          style_formats: [
            { title: "Heading 1", block: "h1" },
            { title: "Heading 2", block: "h2" },
            { title: "Paragraph", block: "p" },
            { title: "Blockquote", block: "blockquote" },
            // { title: "Image formats" },
            // { title: "Medium", selector: "img", classes: "medium" },
          ],
          //   object_resizing: false,
        }}
        value={value}
        onEditorChange={onChangeHandler}
      />
      {error ? <em className="error-red">{error}</em> : null}
    </div>
  );
};

export default TextEditor;
