import Header from "../components/common/header";
// import Card from "../components/common/card";
import Footer from "../components/common/footer";
import QuestTextBox from "../components/common/textbox";

const Home = (props) => {
  return (
    <>
      <div className="section-container box-shadow-default">
        <Header {...props} />
      </div>
      <div className="section-container full-height">
        <div className="input-home">
          <QuestTextBox
            type="text"
            placeHolder="Search your question here..."
            enableIcon
            iconClassName="bi bi-search"
          />
        </div>
      </div>
      {/* //Below items can be added later */}
      {/* <div className="cards-section section-container">
        <Card>
          <img src="" alt="" />
          <p>
            Find the best answer to your technical question, help others answer
            theirs
          </p>
          <button>Join the Community</button>
        </Card>
        <Card style_="white">
          <img src="" alt="" />
          <p>
            Find the best answer to your technical question, help others answer
            theirs
          </p>
          <button>Join the Community</button>
        </Card>
      </div>
      <div className="section-holder">
        <p>Section Content</p>
      </div>
      <div className="cards-section section-container">
        <Card style_="green">
          <img src="" alt="" />
          <p>
            Find the best answer to your technical question, help others answer
            theirs
          </p>
          <button>Join the Community</button>
        </Card>
        <Card style_="white">
          <img src="" alt="" />
          <p>
            Find the best answer to your technical question, help others answer
            theirs
          </p>
          <button>Join the Community</button>
        </Card>
        <Card style_="blue">
          <img src="" alt="" />
          <p>
            Find the best answer to your technical question, help others answer
            theirs
          </p>
          <button>Join the Community</button>
        </Card>
      </div> */}
      <Footer />
    </>
  );
};

export default Home;
