import React, { useEffect, useState } from "react";
import QuestButton from "../common/button";
import Chips from "../common/chips";
import QuestTextBox from "../common/textbox";

const CommentInput = (props) => {
  const {
    formErrors,
    clearSucess,
    resetError,
    item,
    formtype,
    questionId,
    cancel,
  } = props;
  const [input, setInput] = useState({ comment: "", anonymousFlag: false });
  const [pendingUI, setpendingUI] = useState(false);

  const formSuccess = props.formSuccess?.[`comment-${props?.id}`];
  const onChagehandler = (data) => {
    const id = data.id.split("_")[1];
    setInput((prev) => ({ ...prev, [id]: data.value }));
    if (id === "comment" && data.value.length > 5)
      resetError(`comment-${props?.id}`);
  };

  const onSubmit = () => {
    // postcomment/ updatecomment based on id
    props[formtype]({
      ...input,
      commentType: props.id,
      commentId: formtype === "updatecomment" ? item.commentId : 0,
      questionId,
    });
    setpendingUI(true);
  };

  //clearing success on formError or answerCreated
  useEffect(() => {
    if (formErrors || formSuccess) {
      const timer = setTimeout(() => {
        clearSucess();
        setpendingUI(false);
        return () => clearTimeout(timer);
      }, 1000);
    }
  }, [formErrors, clearSucess, formSuccess]);

  useEffect(() => {
    if (formSuccess) {
      setInput({ comment: "", anonymousFlag: false });
    }
    if (formSuccess && item && cancel) {
      const timer = setTimeout(() => {
        cancel();
        return () => clearTimeout(timer);
      }, 1000);
    }
    if (item && formtype === "updatecomment") {
      setInput({
        comment: item?.commentDescription,
        anonymousFlag:
          item?.anonymousFlag === "N" || !item?.anonymousFlag ? false : true,
      });
    }
  }, [formSuccess, cancel, formtype, item]);
  return (
    <>
      <QuestTextBox
        id={props.id + "_comment"}
        placeHolder="Add a comment"
        value={input.comment}
        onChange={onChagehandler}
        name={props.id + "_comment"}
        error={formErrors ? formErrors[`comment-${props.id}`] : null}
      />
      <div className="formQuestion-flag">
        <label
          htmlFor={props.id + "anonymousFlag"}
          title="Click if you don't want to show your name with Comment"
        >
          <QuestTextBox
            type="checkbox"
            id={props.id + "_anonymousFlag"}
            name="comment_anonymousFlag"
            checked={input.anonymousFlag}
            onChange={onChagehandler}
            title="Click if you don't want to show your name with Comment"
            d
          />
          Anonymous
        </label>
        <br />
        <span>
          &nbsp;&nbsp; Optional !
          <br />
          <Chips
            hover="true"
            style_="primary"
            value={
              <p>Click if you don't want to show your name with Comment</p>
            }
          />
        </span>
      </div>
      {formSuccess ? (
        <em className="suceess-green">
          {formSuccess}
          <br />
          <br />
        </em>
      ) : (
        ""
      )}
      <div className="form-button">
        {formtype === "postcomment" ? (
          <QuestButton
            id={props.id + "_commentSubmit"}
            value={pendingUI ? "Posting..." : "Post Comment"}
            theme="green"
            onClick={onSubmit}
            disabled={formErrors?.[`comment-${props.id}`]}
          />
        ) : (
          <QuestButton
            id={props.id + "_commentSubmit"}
            value={pendingUI ? "Updating..." : "Update Comment"}
            theme="secondary"
            onClick={onSubmit}
            disabled={formErrors?.[`comment-${props.id}`]}
          />
        )}
        {props.cancel && (
          <QuestButton
            id={props.id + "_commentCancel"}
            value="cancel"
            theme="primary"
            onClick={() => {
              props.cancel();
            }}
            disabled={formErrors?.[`comment-${props.id}`]}
          />
        )}
      </div>
    </>
  );
};

export default CommentInput;
